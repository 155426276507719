 // AOS.init({
 // 	duration: 800,
 // 	easing: 'slide'
 // });

(function($) {

	"use strict";
    console.log("main.js init");

	//
	// Formation Sticky Menu
	//
	console.log("sticky init");
	let mainNavLinks = document.querySelectorAll("#sticky-nav ul li a");
    
	let offsetTop = document.querySelector('#ftco-navbar').offsetHeight;
	$('#sticky-nav').css('top',offsetTop+"px");

	window.addEventListener("scroll", event => {
		let fromTop = window.scrollY;
		
		mainNavLinks.forEach(link => {
			let section = document.querySelector(link.hash);
			
			if (
				section.offsetTop - 20 <= fromTop &&
				section.offsetTop + section.offsetHeight - 20 > fromTop
			   ) {
					link.classList.add("current");
			} else {
					link.classList.remove("current");
			}
		});
	});

    //
	// $(window).stellar({
  //   responsive: true,
  //   parallaxBackgrounds: true,
  //   parallaxElements: true,
  //   horizontalScrolling: false,
  //   hideDistantElements: false,
  //   scrollProperty: 'scroll',
  //   horizontalOffset: 0,
	//   verticalOffset: 0
  // });
  //
  // // Scrollax
  // $.Scrollax();
  //
  //
	// var fullHeight = function() {
  //
	// 	$('.js-fullheight').css('height', $(window).height());
	// 	$(window).resize(function(){
	// 		$('.js-fullheight').css('height', $(window).height());
	// 	});
  //
	// };
	// fullHeight();
  //
	// loader
	var loader = function() {
		setTimeout(function() {
			if($('#ftco-loader').length > 0) {
				$('#ftco-loader').removeClass('show');
			}
		}, 1);
	};
	loader();
  //
	// // Scrollax
  //  $.Scrollax();

    var carousel_factory = function(element, nbSlide=2) {
        $(element).slick({
            dots: true,
            infinite: true,
            speed: 300,
            slidesToShow: Math.min(1,nbSlide),
            slidesToScroll: Math.min(1,nbSlide),
			centerMode: true,
			centerPadding: '60px',
			variableWidth: true,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: Math.min(1,nbSlide-1),
                        slidesToScroll: Math.min(1,nbSlide-1),
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: Math.min(1,nbSlide-2),
                        slidesToScroll: Math.min(1,nbSlide-2)
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    }
	if (document.querySelectorAll('.avis--carousel').length != 0) {
		carousel_factory('.avis--carousel',4);
	}
	if (document.querySelectorAll('.gallery--carousel').length != 0) {
		carousel_factory('.gallery--carousel',3);
	}

    // var carousel = function() {
	// 	$('.carousel-testimony').owlCarousel({
	// 		center: true,
	// 		loop: false,
	// 		items:1,
	// 		margin: 30,
	// 		stagePadding: 0,
	// 		nav: false,
	// 		navText: ['<span class="ion-ios-arrow-back">', '<span class="ion-ios-arrow-forward">'],
	// 		responsive:{
	// 			0:{
	// 				items: 1
	// 			},
	// 			600:{
	// 				items: 3
	// 			},
	// 			1000:{
	// 				items: 3
	// 			}
	// 		}
	// 	});
    //
	// };
	// carousel();

	// $('nav .dropdown').hover(function(){
	// 	var $this = $(this);
	// 	// 	 timer;
	// 	// clearTimeout(timer);
	// 	$this.addClass('show');
	// 	$this.find('> a').attr('aria-expanded', true);
	// 	// $this.find('.dropdown-menu').addClass('animated-fast fadeInUp show');
	// 	$this.find('.dropdown-menu').addClass('show');
	// }, function(){
	// 	var $this = $(this);
	// 		// timer;
	// 	// timer = setTimeout(function(){
	// 		$this.removeClass('show');
	// 		$this.find('> a').attr('aria-expanded', false);
	// 		// $this.find('.dropdown-menu').removeClass('animated-fast fadeInUp show');
	// 		$this.find('.dropdown-menu').removeClass('show');
	// 	// }, 100);
	// });
  //
  //
	// $('#dropdown04').on('show.bs.dropdown', function () {
	//   console.log('show');
	// });
  //
	// scroll
	var scrollWindow = function() {
		$(window).scroll(function(){
			var $w = $(this),
			st = $w.scrollTop(),
			navbar = $('.ftco_navbar'),
			sd = $('.js-scroll-wrap');
			
			let offsetTop = document.querySelector('#ftco-navbar').offsetHeight;
			$('#sticky-nav').css('top',offsetTop+"px");

			if (st > 50) {
				if ( !navbar.hasClass('scrolled') ) {
					navbar.addClass('scrolled');
				}
			}
			if (st < 50) {
				if ( navbar.hasClass('scrolled') ) {
					navbar.removeClass('scrolled sleep');
				}
			}
			if ( st > 100 ) {
				if ( !navbar.hasClass('awake') ) {
					navbar.addClass('awake');
				}

				if(sd.length > 0) {
					sd.addClass('sleep');
				}
			}
			if ( st < 100 ) {
				if ( navbar.hasClass('awake') ) {
					navbar.removeClass('awake');
					navbar.addClass('sleep');
				}
				if(sd.length > 0) {
					sd.removeClass('sleep');
				}
			}
		});
	};
	scrollWindow();
  //
	//
	// var counter = function() {
	//
	// 	$('#section-counter').waypoint( function( direction ) {
  //
	// 		if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {
  //
	// 			var comma_separator_number_step = $.animateNumber.numberStepFactories.separator(',')
	// 			$('.number').each(function(){
	// 				var $this = $(this),
	// 					num = $this.data('number');
	// 					console.log(num);
	// 				$this.animateNumber(
	// 				  {
	// 				    number: num,
	// 				    numberStep: comma_separator_number_step
	// 				  }, 7000
	// 				);
	// 			});
	//
	// 		}
  //
	// 	} , { offset: '95%' } );
  //
	// }
	// counter();
  //
	var contentWayPoint = function() {
		var i = 0;
		$('.ftco-animate').waypoint( function( direction ) {

			if( direction === 'down' && !$(this.element).hasClass('ftco-animated') ) {

				i++;

				$(this.element).addClass('item-animate');
				setTimeout(function(){

					$('body .ftco-animate.item-animate').each(function(k){
						var el = $(this);
						setTimeout( function () {
							var effect = el.data('animate-effect');
							if ( effect === 'fadeIn') {
								el.addClass('fadeIn ftco-animated');
							} else if ( effect === 'fadeInLeft') {
								el.addClass('fadeInLeft ftco-animated');
							} else if ( effect === 'fadeInRight') {
								el.addClass('fadeInRight ftco-animated');
							} else {
								el.addClass('fadeInUp ftco-animated');
							}
							el.removeClass('item-animate');
						},  k * 50, 'easeInOutExpo' );
					});

				}, 100);

			}

		} , { offset: '95%' } );
	};
	contentWayPoint();
  //
  //
	// navigation
	var OnePageNav = function() {
		$(".smoothscroll[href^='#'], #ftco-nav ul li a[href^='#']").on('click', function(e) {
		 	e.preventDefault();

		 	var hash = this.hash,
		 			navToggler = $('.navbar-toggler');
		 	$('html, body').animate({
		    scrollTop: $(hash).offset().top
		  }, 700, 'easeInOutExpo', function(){
		    window.location.hash = hash;
		  });


		  if ( navToggler.is(':visible') ) {
		  	navToggler.click();
		  }
		});
		$('body').on('activate.bs.scrollspy', function () {
		  console.log('nice');
		})
	};
	OnePageNav();
  //
  //
	// // magnific popup
	// $('.image-popup').magnificPopup({
  //   type: 'image',
  //   closeOnContentClick: true,
  //   closeBtnInside: true,
  //   fixedContentPos: true,
  //   mainClass: 'mfp-no-margins mfp-with-zoom', // class to remove default margin from left and right side
  //    gallery: {
  //     enabled: true,
  //     navigateByImgClick: true,
  //     preload: [0,1] // Will preload 0 - before current, and 1 after the current image
  //   },
  //   image: {
  //     verticalFit: true
  //   },
  //   zoom: {
  //     enabled: true,
  //     duration: 300 // don't foget to change the duration also in CSS
  //   }
  // });
  //
  // $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
  //   disableOn: 700,
  //   type: 'iframe',
  //   mainClass: 'mfp-fade',
  //   removalDelay: 160,
  //   preloader: false,
  //
  //   fixedContentPos: false
  // });
  //
  //
  // $('.appointment_date').datepicker({
	//   'format': 'm/d/yyyy',
	//   'autoclose': true
	// });
  //
	// $('.appointment_time').timepicker();
  //
	// // TYPEWRITE
	// var TxtType = function(el, toRotate, period) {
	//   this.toRotate = toRotate;
	//   this.el = el;
	//   this.loopNum = 0;
	//   this.period = parseInt(period, 10) || 2000;
	//   this.txt = '';
	//   this.tick();
	//   this.isDeleting = false;
	// };
  //
	// TxtType.prototype.tick = function() {
	//   var i = this.loopNum % this.toRotate.length;
	//   var fullTxt = this.toRotate[i];
  //
	//   if (this.isDeleting) {
	//   this.txt = fullTxt.substring(0, this.txt.length - 1);
	//   } else {
	//   this.txt = fullTxt.substring(0, this.txt.length + 1);
	//   }
  //
	//   this.el.innerHTML = '<span class="wrap">'+this.txt+'</span>';
  //
	//   var that = this;
	//   var delta = 200 - Math.random() * 100;
  //
	//   if (this.isDeleting) { delta /= 2; }
  //
	//   if (!this.isDeleting && this.txt === fullTxt) {
	//   delta = this.period;
	//   this.isDeleting = true;
	//   } else if (this.isDeleting && this.txt === '') {
	//   this.isDeleting = false;
	//   this.loopNum++;
	//   delta = 500;
	//   }
  //
	//   setTimeout(function() {
	//   that.tick();
	//   }, delta);
	// };
  //
	// window.onload = function() {
	//   var elements = document.getElementsByClassName('typewrite');
	//   for (var i=0; i<elements.length; i++) {
	//       var toRotate = elements[i].getAttribute('data-type');
	//       var period = elements[i].getAttribute('data-period');
	//       if (toRotate) {
	//         new TxtType(elements[i], JSON.parse(toRotate), period);
	//       }
	//   }
	//   // INJECT CSS
	//   var css = document.createElement("style");
	//   css.type = "text/css";
	//   css.innerHTML = ".typewrite > .wrap { border-right: 2px solid #000;";
	//   document.body.appendChild(css);
	// };
  //



})(jQuery);

